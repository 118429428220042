import { Link } from 'gatsby'
import React from 'react'

// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

const tagName = [
  'badge-warning',
  'badge-info',
  'badge-primary',
  'badge-danger',
  'badge-success'
];

if (!Array.prototype.getRandomIndex) {
  Array.prototype.getRandomIndex = function () {
    return this[Math.floor(Math.random() * this.length)];
  }
}


function BlogPostTags (props) {
  const {label, categories} = props;
  return (
    <Row>
      <Col md={10}>
        <div className="blog-tags">
          { label && (
            <span>Tags:&nbsp;</span>
          )}
          {
            categories && (
              categories.map( cat => (
                <span
                 key={Math.random(10)}
                 className={`badge ${tagName.getRandomIndex()}`}>
                  {cat.title}
                </span>
              ))
            )
          }
        </div>
      </Col>
    </Row>
  )
}
export default BlogPostTags
